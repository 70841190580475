import jsonTransformer from "sites-common/utils/jsonTransformer";
import { formatSys2OdooDate } from "sites-common/utils/nextMonth";
import { validateAge } from "sites-common/utils/validations";

const transformFormDataForSaving = (r) => {
  return jsonTransformer(
    [
      { t: "rel2id", i: "city_id", o: "city_id" },
      { t: "rel2name", i: "city_id", o: "city" },
      { t: "rel2id", i: "country", o: "country" },
      { t: "rel2id", i: "state", o: "state" },
      { t: "rel2id", i: "srcm_group", o: "srcm_group" },
      { t: "rel2id", i: "indiv_sittings_by", o: "indiv_sittings_by" },
      { t: "rel2id", i: "first_sitting_by", o: "first_sitting_by" },
      { t: "copy", i: "id_number", o: "ref" },
    ],
    r
  );
};

const transformApiDataOnFetching = (r) => {
  return jsonTransformer(
    [
      { t: "id2rel", i: "city_id", o: "city_id" },
      { t: "id2rel", i: "srcm_group", o: "srcm_group" },
      { t: "id2rel", i: "indiv_sittings_by", o: "indiv_sittings_by" },
      { t: "id2rel", i: "first_sitting_by", o: "first_sitting_by" },
      { t: "copy", i: "ref", o: "id_number" },
    ],
    r
  );
};

const fieldDefinitionsDict = {
  email: {
    type: "email",
    label: "Email ID",
    icon: "envelope",
    required: false,
  },
  mobile: {
    type: "phone",
    label: "Mobile",
    icon: "mobile",
    enableSearch: true,
    countryCodeEditable: false,
    country: "in",
    required: false,
  },
  first_name: { type: "text", label: "First Name" },
  name: {
    type: "text",
    label: "Display Name (Name on Id Card)",
    required: false,
  },
  last_name: { type: "text", label: "Last Name" },
  middle_name: { type: "text", label: "Middle Name", required: false },
  gender: { type: "gender", label: "Gender", required: false },
  age: {
    type: "number",
    label: "Age",
    min: 15,
    max: 110,
    validator: validateAge,
    required: false,
  },
  state: {
    type: "state_idname",
    label: "State",
  },
  country: {
    type: "country_idname",
    label: "Country",
  },
  city: { type: "text", label: "City" },

  city_id: {
    label: "City",
    type: "city_idname",
    options: {
      city: "name",
      "srcm_group.id": "c_id",
      "srcm_group.name": "c_name",
      "state.id": "state_id",
      "country.id": "country_id",
      "state.name": "state",
      "country.name": "country",
    },
  },
  srcm_group: {
    label: "Nearest Heartfulness Center",
    type: "srcmgroup_idname",
    helperText:
      "where you attend sunday group meditations; enter country name if your center is not visible in dropdown",
  },
  date_of_joining: {
    type: "date",
    label: "Date of First Sitting",
    helperText: "Date of starting Heartfulness Practice",
    validator: (v) => {
      const today = formatSys2OdooDate(new Date());
      if (v > today) {
        return "Future dates are not allowed";
      }
      return false;
    },
  },
  profession: { type: "text", label: "Current Profession", required: false },
  indiv_sittings_by: {
    required: false,
    type: "preceptor_idname",
    label: "Current Preceptor",
    helperText: "Trainer with whom you take individual sittings",
  },
  first_sitting_by: {
    type: "preceptor_idname",
    label: "First Sitting By",
    helperText: "Trainer who gave first sitting",
  },
  photo: { type: "photo", label: "Photo", required: false, hideLabel: true },
  street: { label: "Address (line 1)", required: false },
  street2: { label: "Address (line 2)", required: false },
  street3: { label: "Address (line 3)", required: false },
  street4: { label: "Address (line 4)", required: false },
  postal_code: { label: "Postal Code", required: false },
  id_number: {
    label: "Welcome Paper Id Card Number (if issued)",
    required: false,
    type: "abhyasi_ref_temp",
  },
  ref: { label: "Abhyasi Id", required: false, type: "abhyasi_ref" },
  welcome_card_signed_by_ref: {
    label: "Card Issued By",
    required: true,
    type: "abhyasi_ref",
  },
};

const formDefinition = {
  fields: fieldDefinitionsDict,
  validator: (changed, formData) => {
    const { email, mobile, age, date_of_joining } = formData;
    const errors = {};

    if (!email && !mobile) {
      errors.mobile = "At least one of Email or Mobile should be entered";
    }

    if (age && date_of_joining) {
      const calculatedYearOfBirth = new Date().getFullYear() - age;
      const yearOfJoining = parseInt(date_of_joining.substr(0, 4), 10);

      if (calculatedYearOfBirth + 11 > yearOfJoining) {
        const errorMessage = "Invalid date of starting Heartfulness Practice";

        if ("date_of_joining" in changed) {
          errors.date_of_joining = errorMessage;
        } else {
          errors.age = errorMessage;
        }
      }
    }

    return Object.keys(errors).length > 0 ? errors : false;
  },
  transformFormDataForSaving,
  transformApiDataOnFetching,
  errorTextMissingFields: "Fields marked * are mandatory",
  errorTextInvalidFields: "Please fix form errors to continue saving",
};

export default formDefinition;
